import '../styles/blog.css'

import * as React from 'react'

import { Link, graphql } from 'gatsby'

import { BlogDraftPostsQuery } from '../../graphql-types'
import BlogSidebar from '../components/BlogSidebar'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import Page from '../components/Page'

export const pageQuery = graphql`
  query BlogDraftPosts {
    allMarkdownRemark(sort: { order: DESC, fields: frontmatter___date }) {
      nodes {
        id
        excerpt(pruneLength: 250)
        frontmatter {
          permalink
          date
          image
          draft
          title
        }
      }
    }
  }
`
const PageTwo = ({ data }: { data: BlogDraftPostsQuery }) => {
  const markdownNodes = data?.allMarkdownRemark?.nodes
  // console.log(Posts)
  return (
    <div className="blog">
      <BlogSidebar />
      <div className="blog-content">
        <h1 className="text-red-500 text-5xl">DRAFT Posts</h1>
        <ul>
          {markdownNodes.map((node) => {
            const { permalink, title, draft } = node.frontmatter ?? {}
            return permalink && title && draft ? (
              <li>
                <Link to={permalink} key={permalink}>
                  {title}
                </Link>
              </li>
            ) : null
          })}
        </ul>
      </div>
    </div>
    // <IndexLayout>
    //   <Page>
    //     <Container>
    //     </Container>
    //   </Page>
    // </IndexLayout>
  )
}

export default PageTwo
