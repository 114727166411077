import '../styles/blog.css'

import * as React from 'react'

import { Link, graphql, useStaticQuery } from 'gatsby'

import { GatsbyImage } from 'gatsby-plugin-image'
import { SidebarAvatarQuery } from '../../graphql-types'

const BlogSidebar: React.FC<{}> = ({ children }) => {
  const data = useStaticQuery<SidebarAvatarQuery>(query)
  const imgData = data?.fileName?.childImageSharp?.gatsbyImageData
  return (
    <div className="sidebar">
      <div className="px-6 flex justify-center">{imgData && <GatsbyImage className="avatar-image" image={imgData as any} alt="" />}</div>
      <div className="mx-6 px-6 text-center border-opacity-50">
        <Link className="name" to="/">
          Max Cantor
        </Link>
      </div>
      <p className="leading-tight text-center lg:text-left mb-6 p-3 text-base ">
        <Link to="/blog">A blog of thoughts on management, tech, aviation, and fitness.</Link>
      </p>
      {children}
    </div>
  )
}

export default BlogSidebar

export const query = graphql`
  query SidebarAvatar {
    fileName: file(relativePath: { eq: "headshot.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 150, height: 150, layout: CONSTRAINED)
      }
    }
  }
`
